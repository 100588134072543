// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-recipe-js": () => import("/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/src/pages/recipe.js" /* webpackChunkName: "component---src-pages-recipe-js" */),
  "component---src-pages-404-js": () => import("/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-js": () => import("/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */)
}

exports.data = () => import("/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/.cache/data.json")

