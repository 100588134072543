module.exports = [{
      plugin: require('/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-18243278-7","head":false,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"cookwithpom.com"},
    },{
      plugin: require('/Users/r.goyard/Code/perso/pom-cooking-class/apps/www/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
